<template>
	<div class="box">
		<!-- header -->
		<headers></headers>
		<!-- 标题 -->
		<div class="power-top">
			<img src="../../assets/images/del.png" alt="" class="del" @click="back" />
			<div class="power-title">
				<img src="../../assets/images/t-l.png" alt="" />
				<span>出入库数据信息统计</span>
				<img src="../../assets/images/t-r.png" alt="" />
			</div>
		</div>
		<!-- <el-date-picker type="daterange" v-model="dctime" @change="daochutime" value-format="yyyy-MM-dd"
			range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
		<div class="daochu" @click="exports">导出</div> -->

		<!-- 数据内容 -->
		<div class="crdata-con">
			<!-- 出入库数据日统计 -->
			<div class="cr-day">
				<div class="p-con-title ">
					<div class="p-con-title-bg"><span>出入库数据日统计</span></div>
					<el-date-picker @change="getrq1" value-format="yyyy-M-d" v-model="time1" type="date"
						placeholder="选择日期"></el-date-picker>
				</div>
				<!-- 当前场内存量 今日原材料入场 -->
				<div class="cr-day-center">
					<div class="cr-day-item">
						<span>今日生产总量</span>
						<p>
							{{Number(ridata.shengchan).toFixed(2)}}
							<span>T</span>
						</p>
					</div>
					<div class="cr-day-item">
						<span>剩余库存</span>
						<p>
							{{zshengyu}}
							<span>T</span>
						</p>
					</div>
				</div>
				<!-- 图示 -->
				<div class="cr-tushi">
					<!-- <dv-active-ring-chart :config="config4" style="width:240px;height:240px"  /> -->
					<div id="pieO"></div>
					<div class="jrcpl-list">
						<div class="jrcpl-list-item" v-for="(crk2, index) in config4.data" :key="index">
							<div class="jrcpl-item">
								<div class="jrcpl-item-con">
									<div :class="'kuai'+index"></div>
									<span>{{ crk2.name }}</span>
								</div>
								<p>
									{{ crk2.value }}
									<span>T</span>
								</p>
							</div>
							<div class="dian"></div>
						</div>
					</div>
				</div>
			</div>
			<!-- 出入库数据月统计 -->
			<div class="cr-day">
				<div class="p-con-title ">
					<div class="p-con-title-bg"><span>出入库数据月统计</span></div>
					<el-date-picker @change="getrq2" value-format="yyyy-M" v-model="time2" type="month"
						placeholder="选择日期"></el-date-picker>
				</div>
				<!-- 当前场内存量 今日原材料入场 -->
				<div class="cr-day-center">
					<div class="cr-day-item">
						<span>本月生产总量</span>
						<p>
							{{Number(yuedata.shengchan).toFixed(2)}}
							<span>T</span>
						</p>
					</div>
					<div class="cr-day-item">
						<span>剩余库存</span>
						<p>
							{{zshengyu}}
							<span>T</span>
						</p>
					</div>
				</div>
				<!-- 图示 -->
				<div class="cr-tushi">
					<div id="pieT"></div>
					<!-- <dv-active-ring-chart :config="crk2" style="width:240px;height:240px" /> -->
					<div class="jrcpl-list">
						<div class="jrcpl-list-item" v-for="(crk2, index) in crk2.data" :key="index">
							<div class="jrcpl-item">
								<div class="jrcpl-item-con">
									<div :class="'kuai'+index"></div>
									<span>{{ crk2.name }}</span>
								</div>
								<p>
									{{ crk2.value }}
									<span>T</span>
								</p>
							</div>
							<div class="dian"></div>
						</div>
					</div>
				</div>
			</div>
			<!--出入库数据年统计 -->
			<div class="cr-day">
				<div class="p-con-title ">
					<div class="p-con-title-bg"><span>出入库数据年统计</span></div>
					<el-date-picker @change="getrq3" value-format="yyyy" v-model="time3" type="year"
						placeholder="选择日期"></el-date-picker>
				</div>
				<!-- 当前场内存量 今日原材料入场 -->
				<div class="cr-day-center">
					<div class="cr-day-item">
						<span>今年生产总量</span>
						<p>
							{{Number(niandata.shengchan).toFixed(2)}}
							<span>T</span>
						</p>
					</div>
					<div class="cr-day-item">
						<span>剩余库存</span>
						<p>
							{{zshengyu}}
							<span>T</span>
						</p>
					</div>
				</div>
				<!-- 图示 -->
				<div class="cr-tushi">
					<div id="pieTh"></div>
					<!-- <dv-active-ring-chart :config="crk3" style="width:240px;height:240px" /> -->
					<div class="jrcpl-list">
						<div class="jrcpl-list-item" v-for="(crk2, index) in crk3.data" :key="index">
							<div class="jrcpl-item">
								<div class="jrcpl-item-con">
									<div :class="'kuai'+index"></div>
									<span>{{ crk2.name }}</span>
								</div>
								<p>
									{{ crk2.value }}
									<span>T</span>
								</p>
							</div>
							<div class="dian"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import headers from '../../components/headers.vue';
	// const config4 = {

	// };
	const crk2 = {
		radius: '78%',
		activeRadius: '80%',
		data: [{
				name: '生产总量',
				value: 300
			},
			{
				name: '销售总量',
				value: 200
			},
			{
				name: '剩余库存',
				value: 100
			}
		],
		digitalFlopStyle: {
			fontSize: 20
		},
		showOriginValue: true,
		lineWidth: 8,
		color: ['#159AFF', '#D0DEEE', '#66E1DF']
	};
	const crk3 = {
		radius: '78%',
		activeRadius: '80%',
		data: [{
				name: '生产总量',
				value: 3600
			},
			{
				name: '销售总量',
				value: 3300
			},
			{
				name: '剩余库存',
				value: 300
			}
		],
		digitalFlopStyle: {
			fontSize: 20
		},
		showOriginValue: true,
		lineWidth: 8,
		color: ['#159AFF', '#D0DEEE', '#66E1DF']
	};
	import * as echarts from 'echarts';
	export default {
		components: {
			headers
		},
		data() {
			return {
				time1: '',
				time2: '',
				time3: '',
				config4: "",
				dctime: '',
				starttime: '',
				endtime: '',
				crk2,
				crk3,
				ridata: [],
				yuedata: [],
				niandata: [],
				zshengyu: "" //总剩余
			};
		},
		mounted() {
			const date = new Date();
			const day = date.getDate();
			const month = date.getMonth() + 1;
			const year = date.getFullYear();
			this.time3 = year.toString();
			this.time2 = year + '-' + month;
			this.time1 = year + '-' + month + '-' + day;
			this.getcrkdata()
		},
		methods: {
			// 倒出时间区间
			daochutime() {
				if (this.dctime) {
					this.starttime = this.dctime[0]
					this.endtime = this.dctime[1]
				} else {
					this.starttime = ''
					this.endtime = ''
				}
			},
			// 导出
			async exports() {
				if (this.starttime == "" || this.endtime == "") {
					this.$message({
						message: '请选择导出时间范围',
						type: 'warning'
					})

				} else {
					const res = await this.$postToken('datas/pidai-daochu', {
						s_time: this.starttime,
						e_time: this.endtime,
					})
					// 
					console.log(res)
					if (res.data.code == 200) {
						window.location.href = res.data.result.url
					}
				}

			},
			// 返回上一级
			back() {
				this.$router.back();
			},
			//生产线日统计
			getrq1(e) {
				this.getcrkdata()
				// console.log(this.time1);
			},
			// 月
			getrq2() {
				this.getcrkdata()
				// console.log(this.time2);
			},
			// 年
			getrq3() {
				this.getcrkdata()
				// console.log(this.time3);
			},
			pie1() {
				let that = this
				var chartDom2 = document.getElementById('pieO');
				var myChart2 = echarts.init(chartDom2);
				var option;
				option = {
					tooltip: {
						trigger: 'item',
						showContent: false,
						enterable: false,
					},
					color: [
						'#159aff', '#d0deee', '#66e1df'
					],
					legend: {
						top: '5%',
						left: 'center'
					},
					series: [{
						type: 'pie',
						radius: ['60%', '70%'],
						avoidLabelOverlap: false,
						itemStyle: {
							borderRadius: 4,
							borderColor: '#fff',
							borderWidth: 2
						},
						label: {
							show: false,
							position: 'center'
						},

						emphasis: {
							label: {
								show: true,
								fontSize: 18,
								fontWeight: ''
							}
						},
						labelLine: {
							show: false
						},
						data: [{
								value: that.ridata.shengchan
							},
							{
								value: that.ridata.xiaoshou
							},
							{
								value: that.zshengyu
							}
						]
					}]
				};

				option && myChart2.setOption(option);
			},
			pie2() {
				let that = this
				var chartDom3 = document.getElementById('pieT');
				var myChart3 = echarts.init(chartDom3);
				var option;
				option = {
					tooltip: {
						trigger: 'item',
						showContent: false,
						enterable: false,
					},
					color: [
						'#159aff', '#d0deee', '#66e1df'
					],
					legend: {
						top: '5%',
						left: 'center'
					},
					series: [{
						name: 'Access From',
						type: 'pie',
						radius: ['60%', '70%'],
						avoidLabelOverlap: false,
						itemStyle: {
							borderRadius: 4,
							borderColor: '#fff',
							borderWidth: 2
						},
						label: {
							show: false,
							position: 'center'
						},
						emphasis: {
							label: {
								show: true,
								fontSize: 18,
								fontWeight: ''
							}
						},
						labelLine: {
							show: false
						},
						data: [{
								value: that.yuedata.shengchan
							},
							{
								value: that.yuedata.xiaoshou
							},
							{
								value: that.zshengyu
							}
						]
					}]
				};
				option && myChart3.setOption(option);
			},
			pie3() {
				let that = this
				var chartDom = document.getElementById('pieTh');
				var myChart = echarts.init(chartDom);
				var option;

				option = {
					tooltip: {
						trigger: 'item',
						showContent: false,
						enterable: false,
					},
					color: [
						'#159aff', '#d0deee', '#66e1df'
					],
					legend: {
						top: '5%',
						left: 'center'
					},
					series: [{
						name: 'Access From',
						type: 'pie',
						radius: ['60%', '70%'],
						avoidLabelOverlap: false,
						itemStyle: {
							borderRadius: 4,
							borderColor: '#fff',
							borderWidth: 2
						},
						label: {
							show: false,
							position: 'center'
						},
						emphasis: {
							label: {
								show: true,
								fontSize: 18,
								fontWeight: ''
							}
						},
						labelLine: {
							show: false
						},
						data: [{
								value: that.niandata.shengchan
							},
							{
								value: that.niandata.xiaoshou
							},
							{
								value: that.zshengyu
							}
						]
					}]
				};
				option && myChart.setOption(option);
			},

			// 获取数据
			async getcrkdata() {
				const data = {}
				const res = await this.$postToken('datas/pidai-datas', {
					r_time: this.time1,
					y_time: this.time2,
					n_time: this.time3
				});
				if (res.data.code == 200) {
					this.ridata = res.data.result.ri
					this.yuedata = res.data.result.yue
					this.niandata = res.data.result.nian
					this.config4 = {
						data: [{
								name: '生产总量',
								value: Number(res.data.result.ri.shengchan).toFixed(2)
							},
							{
								name: '销售总量',
								value: Number(res.data.result.ri.xiaoshou).toFixed(2)
							},
							{
								name: '剩余库存',
								value: Number(res.data.result.zongshengyu).toFixed(2)
							}
						],
					}
					this.zshengyu = Number(res.data.result.zongshengyu).toFixed(2)
					this.pie1()
					this.pie2()
					this.pie3()
					// this.config4.data=
					crk2.data = [{
							name: '生产总量',
							value: Number(res.data.result.yue.shengchan).toFixed(2)
						},
						{
							name: '销售总量',
							value: Number(res.data.result.yue.xiaoshou).toFixed(2)
						},
						{
							name: '剩余库存',
							value: Number(res.data.result.zongshengyu).toFixed(2)
						}
					]
					crk3.data = [{
							name: '生产总量',
							value: Number(res.data.result.nian.shengchan).toFixed(2)
						},
						{
							name: '销售总量',
							value: Number(res.data.result.nian.xiaoshou).toFixed(2)
						},
						{
							name: '剩余库存',
							value: Number(res.data.result.zongshengyu).toFixed(2)
						}
					]
				}
			}

		}
	};
</script>
<style>
	.box {
		position: relative;
	}

	/* 标题 */
	.power-top {
		width: 100%;
		margin: 0 1%;
		position: relative;
		display: flex;
	}

	.del {
		display: block;
		left: 0;
	}

	.power-title {
		width: 400px;
		margin: 0 auto;
		text-align: center;
		color: #fff;
		font-size: 32px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.power-title img {
		display: block;
		width: 21px;
		height: 20px;
	}

	/* 出入库数据日统计 */
	.crdata-con {
		margin: 60px 1% 0 1%;
		display: flex;
		justify-content: space-between;
	}

	.cr-day {
		width: 560px;
		height: 532px;
		background: rgba(21, 154, 255, 0.05);
		padding: 30px;
	}

	/* 统计标题 */
	.p-con-title {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		color: #fff;
	}

	.p-con-title-bg {
		height: 40px;
		background: url(../../assets/images/title\(1\).png) no-repeat left bottom;
		padding: 0 0 0px 40px;
	}

	.p-con-title-bg span {
		display: block;
		margin-bottom: 0px;
		font-size: 24px;
		font-weight: bold;
	}

	/* 内存量  原材料入场*/
	.cr-day {
		flex: 1;
		margin: 0 10px;
	}

	.cr-day-center {
		display: flex;
		justify-content: space-between;
		margin: 40px 0;
	}

	.cr-day-center .cr-day-item {
		width: 240px;
		height: 112px;
		padding-left: 26px;
		background: linear-gradient(90deg, rgba(21, 154, 255, 0) 0%, rgba(4, 34, 71) 31%, rgba(4, 34, 71) 69%, rgba(21, 154, 255, 0) 100%);
		border-left: 4px solid #159aff;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
	}

	.cr-day-center .cr-day-item span {
		font-size: 20px;
		color: #d0deee;
	}

	.cr-day-center .cr-day-item p {
		font-size: 34px;
		font-weight: bold;
		color: #ffffff;
	}

	.cr-day-center .cr-day-item p span {
		font-weight: normal;
		font-size: 14px;
	}

	/* 图示 */
	.cr-tushi {
		display: flex;
		align-items: center;
	}

	.jrcpl-item .jrcpl-item-con {
		display: flex;
		align-items: center;
	}

	.jrcpl-list {
		width: 65%;
		height: 100px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.jrcpl-list-item {
		display: flex;
		align-items: flex-end;
	}

	.jrcpl-item {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid rgba(108, 128, 151, 0.5);
	}

	.jrcpl-item span {
		font-size: 14px;
		font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
		font-weight: 400;
		color: #d0deee;
	}

	.jrcpl-item p {
		font-size: 18px;
		font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
		font-weight: 500;
		color: #ffffff;
	}

	.jrcpl-item div div {
		width: 10px;
		height: 10px;
		background: #159aff;
		border-radius: 0px 0px 0px 0px;
		opacity: 1;
		margin-right: 10px;
	}

	.dian {
		width: 4px;
		height: 4px;
		background: #6c8097;
		border-radius: 100%;
	}

	.jrcpl-item div .cpl2 {
		background: #d0deee;
	}

	.jrcpl-item div .cpl3 {
		background: #66e1df;
	}

	.el-input .el-input__inner {
		background: #011633;
		border: 1px solid;
		color: #fff;
		border-image: linear-gradient(134deg, rgba(21, 154, 255, 1), rgba(102, 225, 223, 1)) 1 1;
	}

	.el-date-picker__header-label,
	.el-picker-panel__icon-btn {
		color: #fff !important;
	}

	.el-picker-panel,
	.el-year-table a,
	.el-month-table a {
		background: #011633;
		color: #fff !important;
	}

	/*  */
	.jrcpl-item .jrcpl-item-con .kuai1 {
		background-color: #D0DEEE;
	}

	.jrcpl-item .jrcpl-item-con .kuai2 {
		background-color: #66e1df;
	}

	#pieO,
	#pieT,
	#pieTh {
		width: 240px;
		height: 240px;
	}

	.el-date-picker__header-label,
	.el-picker-panel__icon-btn {
		color: #fff !important;
	}

	.el-picker-panel,
	.el-year-table a,
	.el-month-table a {
		background: #011633;
		color: #fff !important;
	}

	.el-range-input {
		color: #fff !important;
	}

	.daochu {
		background: #13374c;
		color: #fff;
		padding: 4px 10px;
		border-radius: 4px;
		margin-left: 20px;
		font-size: 16px;
	}
</style>